.delivery-section{
    background: url('../images/Image1.jpg');
    background-repeat: no-repeat;
    border-radius: 20px;
    background-size: cover;
    margin: 0 auto;
    padding: 105px 0;
    background-position: top center;
    color: #000;
    .delivery-text{
        padding-inline: 17rem;
        @media screen and (max-width: 992px) {
            padding-inline: 12rem;
        }
        @media screen and (max-width: 780px) {
            padding-inline: 0;
            h1{
                font-size: 30px
            }
        }
    }
    h3{
        font-family: 'Norican', cursive;
        color: #e25730;
        font-size: 48px;
        font-weight: normal
    }
    h1{
        font-weight: 600;
        font-size: 40px;
        color: #000;
    }
    p{
        font-size: 12px;
    }
    button{
        box-shadow: 0px 14px 32px 0px rgba(255, 178, 14, 0.29), 0px 5px 8px 0px rgba(222, 151, 0, 0.24);
        border-radius: 13px !important;
        border: none;
        padding: 16px 23px;
        font-weight: 500;
        font-size: 15px;
        @media screen and (max-width: 780px) {
            font-size: 12px;
            padding: 12px 16px;
        }
    }
    @media screen and (max-width:1083px) {
        padding: 105px 200px;
    }
    @media screen and (max-width:767px) {
        padding: 105px 100px;
    }
    @media screen and (max-width: 555px){
        padding: 90px 0;
    }
}