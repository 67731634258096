@import "./icons.scss";
@import "./card";
@import "./slider";
@import "./tab";
@import "./newsletter";
@import "./delivery";
// Light Theme
@import "./bootstrap.scss";
@import "./app.scss";

//font
@import url("https://fonts.googleapis.com/css2?family=Norican&display=swap");
// RTL Mode
// @import "./rtl/bootstrap-rtl";
// @import "./rtl/components-rtl";
// @import "./rtl/float-rtl";
// @import "./rtl/general-rtl";
// @import "./rtl/pages-rtl";
// @import "./rtl/plugins-rtl";
// @import "./rtl/spacing-rtl";
// @import "./rtl/structure-rtl";
// @import "./rtl/text-rtl";

body {
  * {
    outline: none;
  }
}

#sidebar-menu {
  ul {
    li {
      a {
        i {
          min-width: 2rem;
        }
      }
    }
  }
}

.leaflet-pane {
  z-index: 1;
}

svg > rect:first-child {
  fill-opacity: 0 !important;
}

.dataTables_filter,
.dataTables_paginate {
  float: right;
}

.rdw-editor-main {
  border: 1px solid $gray-300;
  height: 239px;
  padding-left: 15px;
}

.dz-message {
  text-align: center;
  padding: 30px;
}

// kanban board
.react-kanban-board {
  display: inherit !important;

  .react-kanban-column {
    background-color: $white;
    padding: 1.25rem;

    [data-rbd-draggable-context-id] {
      > div {
        display: block !important;
      }
    }
  }

  > div {
    display: flex;
    justify-content: center;
    gap: 25px;

    @media (max-width: 991.98px) {
      flex-direction: column;

      .react-kanban-column {
        width: 100%;
      }
    }
  }
}

.react-kanban-column {
  width: 33.33%;
}

.react-kanban-column > div {
  display: block !important;
}

.fcYNFc,
.sc-AxmLO.gmtmqV {
  background-color: $card-bg !important;
  margin: 0 20px 0 0 !important;
}

.task-box {
  border: 1px solid $border-color;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.external-event {
  &:hover {
    cursor: pointer;
  }
}

a:hover {
  cursor: pointer;
}

.rating-container {
  background-color: transparent !important;
}

.input-group-append {
  z-index: 0;
}

.input-color {
  color: $input-color !important;
}

.sketch-picker {
  position: absolute;
  z-index: 1;
}

.rangeslider__fill {
  background-color: $primary !important;
}

.dropdown-menu-end {
  left: auto !important;
  right: 0 !important;
}

.flatpickr-months .flatpickr-month {
  background: $primary !important;
}

.flatpickr-weekdays {
  background: $primary !important;
}

span.flatpickr-weekday {
  background: $primary !important;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  background: $primary !important;
}

span.flatpickr-weekday {
  color: $white !important;
}

.flatpickr-day.selected {
  background: $primary !important;
}

.bs-select select {
  width: auto !important;
}

//File-manager
.file-manager {
  .table td {
    padding: 0.35rem;
  }
}

//Authenication carousel
.slider_css {
  .slide {
    background: none !important;
  }

  .carousel-status,
  .control-next,
  .control-prev {
    display: none !important;
  }
}

.carousel {
  .control-dots {
    margin: -5px 0px;
  }

  .dot {
    background: #556ee6 !important;
    width: 9px !important;
    height: 9px !important;
  }
}

//Two verification Page
.verification {
  input {
    &:focus {
      border: none;
      outline: none !important;
    }
  }
}

@media (max-width: 768px) {
  .verification {
    input {
      width: 40px !important;
    }
  }
}

@media (max-width: 320px) {
  .verification {
    input {
      width: 30px !important;
      height: 30px !important;
      font-size: 15px !important;
    }
  }
}

//Two verification Page - 2
.verification-2 {
  input {
    &:focus {
      border: none;
      outline: none !important;
    }
  }
}

@media (max-width: 768px) {
  .verification-2 {
    input {
      width: 40px !important;
      font-size: 14px;
    }
  }
}

@media (max-width: 320px) {
  .verification-2 {
    input {
      width: 30px !important;
      height: 35px !important;
      font-size: 15px !important;
    }
  }
}

//Calendar
.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  padding: 5px !important;
  font-size: 12px !important;
  margin-right: 2px;
}

@include media-breakpoint-down(sm) {
  .fc-toolbar {
    display: block !important;
    text-align: center;

    .fc-left,
    .fc-right,
    .fc-center {
      float: none;
      display: block;
      clear: both;
      margin: 10px 0;
    }
  }

  .fc {
    .fc-toolbar {
      > * {
        > * {
          float: none;
        }
      }
    }
  }

  .fc-today-button {
    display: none;
  }
}

//Chat Scrollbar
.ps__rail-y {
  z-index: 999999 !important;
}

.close {
  background: transparent;
  border: 0;
  font-size: 10px;
  padding: 1.35rem 1.25rem;
  background: transparent escape-svg($btn-close-bg) center / $btn-close-width
    auto no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.5;
  width: 1em;
  height: 1em;
  z-index: 2;

  span {
    display: none;
  }
}

//
// Columns
//

.card-columns {
  .card {
    margin-bottom: $grid-gutter-width;
  }

  @include media-breakpoint-up(sm) {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;

    .card {
      display: inline-block; // Don't let them vertically span multiple columns
      width: 100%; // Don't let their width change
    }
  }
}

/* drawer */
.react-drawer-drawer {
  z-index: 9999;
  background: #fff;
}

.drawer-main-menu {
  padding-top: 8px;
  padding-bottom: 8px;
}

.drawer-menu a i {
  min-width: 2rem;
  display: inline-block;
  min-width: 1.75rem;
  padding-bottom: 0.125em;
  font-size: 1.25rem;
  line-height: 1.40625rem;
  vertical-align: middle;
  color: #7f8387;
  transition: all 0.4s;
}

.drawer-menu a {
  display: block;
  padding: 0.625rem 1.5rem;
  color: #545a6d;
  position: relative;
  font-size: 13px;
  transition: all 0.4s;
}

.drawer-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.ReactModal__Overlay {
  z-index: 5000 !important;
}

.rangeslider__handle:focus {
  outline: none;
}

// React Table next css
.react-bootstrap-table-page-btns-ul {
  float: right !important;
}

.search-label {
  float: right;
}

.react-drawer-overlay {
  z-index: 9999;
}

// datatable

.react-bs-table-sizePerPage-dropdown {
  .dropdown-menu {
    .dropdown-item {
      a {
        display: block;
      }
    }
  }
}
.rightbar-title{
  position: sticky;
  top: 0;
  z-index: 2;
  background: #fff;
  .right-bar-toggle {
    height: 24px !important;
    width: 24px !important;
    line-height: 24px !important;
    display: block !important;
    text-align: center !important;
    border-radius: 50% !important;
    background: transparent;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 0.3s ease-in-out;
    &:hover{
      transform: rotate(180deg);
    }
  }
}
//rightbar drawer
.css-sourceMap-modules-importLoaders-1-localIdentName-animate__animated___2O131 {
  animation-duration: 0.6s !important;
}

.css-sourceMap-modules-importLoaders-1-localIdentName-ReactDrawer__overlay___2QFmC-sass {
  background: rgba(0, 0, 0, 0.15) !important;
}

//react-table
.custom-header-css {
  thead {
    tr {
      th {
        div:last-child {
          min-height: 38px;
        }
      }
    }

    td:nth-last-child(2) {
      width: 150px !important;
    }
  }
}

.editor_email .ck-content {
  height: 200px;
}

// .my-masonry-grid {
//   display: -webkit-box;
//   /* Not needed if autoprefixing */
//   display: -ms-flexbox;
//   /* Not needed if autoprefixing */
//   display: flex;
//   margin-left: -25px;
//   /* gutter size offset */
//   width: auto;
// }

// .my-masonry-grid_column {
//   padding-left: 25px;
//   /* gutter size */
//   background-clip: padding-box;
// }

// /* Style your items */
// .my-masonry-grid_column>div {
//   /* change div to reference your elements you put in <Masonry> */
//   background: transparent;
//   margin-bottom: 20px;
// }

.jobs-categories .card-body a:last-child {
  margin-bottom: 0 !important;
}

@media (max-width: 991.98px) {
  .search-box {
    display: block !important;
    .search-label {
      float: none;
      width: 100%;
      .form-control {
        border-radius: 0.25rem !important;
      }
    }
  }
}

// select

.css-1s2u09g-control,
.css-1pahdxg-control {
  background-color: $input-bg !important;
  border-color: $input-border-color !important;
}

.css-14el2xx-placeholder {
  color: $input-placeholder-color !important;
}

.css-26l3qy-menu {
  box-shadow: none !important;
  border: 1px solid $input-border-color !important;
}

.css-4ljt47-MenuList {
  background-color: var(--#{$prefix}custom-white) !important;
  border-radius: 4px !important;
}

.css-319lph-ValueContainer .css-qc6sy-singleValue {
  color: $input-color !important;
}
.select2-selection .css-b62m3t-container {
  color: $input-color !important;
}
.css-1n7v3ny-option,
.css-9gakcf-option {
  background-color: $primary !important;
  color: $white !important;
}

// rdw-editor

.rdw-editor-toolbar,
.rdw-option-wrapper,
.rdw-editor-main {
  border-color: $input-border-color !important;
  background-color: var(--#{$prefix}custom-white) !important;
}

.rdw-option-wrapper {
  border: none !important;
  box-shadow: none !important;
}

.rdw-dropdown-wrapper {
  background-color: $input-bg !important;
  border-color: $input-border-color !important;
  box-shadow: none !important;
}

.rdw-dropdown-selectedtext {
  color: var(--#{$prefix}body-color) !important;
}

[data-layout-mode="dark"] {
  .rdw-option-wrapper,
  .rdw-dropdown-selectedtext {
    img {
      filter: invert(1) !important;
    }
  }
}

// ck editor

.ck.ck-toolbar {
  background-color: var(--#{$prefix}light) !important;
  border: 1px solid $input-border-color !important;
}

.ck-reset_all :not(.ck-reset_all-excluded *),
.ck.ck-reset_all {
  color: var(--#{$prefix}body-color) !important;
}

.ck.ck-editor__main > .ck-editor__editable {
  background-color: var(--#{$prefix}custom-white) !important;
  color: var(--#{$prefix}body-color) !important;
  height: 300px !important;
}

.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
  border-color: $input-border-color !important;
}

.ck.ck-button:not(.ck-disabled):hover,
a.ck.ck-button:not(.ck-disabled):hover,
.ck.ck-button.ck-on,
a.ck.ck-button.ck-on {
  background-color: var(--#{$prefix}light) !important;
}

.ck.ck-toolbar .ck.ck-toolbar__separator {
  background: $input-border-color !important;
}

.selectall.checkbox-wrapper-mail label:before {
  margin-top: 0;
}

// scroll
.ps__rail-y,
.ps__rail-x {
  z-index: 999 !important;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--#{$prefix}custom-white) inset !important;
  background-color: var(--#{$prefix}custom-white) !important;
  color: var(--#{$prefix}body-color) !important;
  -webkit-text-fill-color: var(--#{$prefix}body-color) !important;
}

// Select2

.select2-selection__control,
.select__control,
.select2-selection .css-13cymwt-control,
.css-t3ipsp-control {
  background-color: $input-bg !important;
  border-color: $input-border-color !important;
}

.select2-selection__menu,
.select__menu,
.css-1nmdiq5-menu {
  background-color: var(--#{$prefix}card-bg) !important;
  box-shadow: 0 0 0 1px var(--#{$prefix}border-color),
    0 4px 11px var(--#{$prefix}border-color) !important;
}

.select2-selection__option--is-focused,
.select__option--is-focused,
.css-d7l1ni-option {
  background-color: $primary !important;
  color: $white !important;
}

.select__multi-value,
.select2-selection__multi-value,
.css-1p3m7a8-multiValue {
  background-color: var(--#{$prefix}light) !important;
}

.select__multi-value__label,
.select2-selection__multi-value__label,
.css-qbdosj-Input,
.css-1dimb5e-singleValue,
.css-wsp0cs-MultiValueGeneric {
  color: var(--#{$prefix}body-color) !important;
}

.select__multi-value__remove,
.select2-selection__multi-value__remove {
  background-color: var(--#{$prefix}light) !important;
  color: var(--#{$prefix}body-color) !important;
}

.css-13cymwt-control, .form-control {
  border-radius: 10px !important;
  height: 45px;
}
// auth-logo

[data-layout-mode="dark"] {
  .auth-logo {
    .auth-logo-light {
      display: block;
    }
    .auth-logo-dark {
      display: none;
    }
  }
}
.page-btn {
  background: var(--theme-button-bg);
  border: 1px solid var(--theme-button-bg);
  color: var(--theme-button-text);
  padding: 25px;
  font-size: 15px;
  text-transform: uppercase;
  border-radius: 15px;
  line-height: 0;
  a{
    color: var(--theme-button-text);
  }
  &:is(:hover, :focus, :active) {
    background-color: var(--theme-button-hover-bg) !important;
    color: var(--theme-button-hover-text) !important;
    border-color: var(--theme-button-hover-text);
    a{
      color: var(--theme-button-hover-text) !important;
    }
  }
}
.branchClose-text{
  color: red !important;
}
.disablePageButton{
  background: #757474;
  color: #fff;
  border: none;
  padding: 25px;
  font-size: 15px;
  text-transform: uppercase;
  border-radius: 15px;
  line-height: 0;
  a{
    color: #fff;
  }
  &:is(:hover, :focus, :active) {
    background-color: transparent !important;
    color: #757474 !important;
    border: 1px solid #757474;
    border-color: #757474 !important;
    a{
      color: #757474 !important;
    }
  }
}
.page-padding {
  padding-inline: 0;
  background: #fff;
}
.page-item {
  &.active {
    .page-link {
      background: #e25730;
      border-color: #e25730;
    }
  }
}

.modal-dialog {
  max-width: 94vw;
  width: 500px;
}

//search icon style

.search-icon {
  cursor: pointer;
}

.search-bar {
  display: flex;
  align-items: center;
  margin-left: 10px;
  width: 0;
  overflow: hidden;
  transition: width 0.3s ease-in-out;
  input {
    border: none;
    outline: none;
    background: transparent;
    font-size: 13px;
    padding: 5px;
  }
}

.search-container {
  display: flex;
  align-items: center;
  padding: 0.75rem;
  border-radius: 5px;
  transition: width 0.3s ease-in-out;
  &.expanded {
    padding: 0 10px;
    width: 200px;
    border: 1px solid #e5e5e5;
    border-radius: 70px;
    position: relative;
    height: fit-content;
  }
  .search-bar {
    width: 150px;
  }
  @media screen and (max-width: 767px) {
    display: none;
  }
}
.topbar-text {
  color: var(--accent-text);
  background: var(--accent-bg);
  padding: 5px 0;
}

.cursor-pointer {
  cursor: pointer !important;
}
.header-logo {
  vertical-align: middle;
  display: inline-block;
  height: auto;
  width: 20%;
  filter: brightness(0);
  margin-inline: 1rem;
}
.modal-content {
  border-radius: 23px;
}
.cart-icon {
  padding: 0.75rem;
  font-size: 20px;
  .badge {
    position: absolute;
    right: -15px;
    top: 0;
    border-radius: 50% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    line-height: 0;
    height: 5px;
    width: 5px;
    font-size: 12px;
    color: var(--header-counter-text) !important;
    background-color: var(--header-counter) !important;
  }
  @media screen and (max-width: 992px) {
    display: none;
  }
  @media screen and (max-width: 767px) {
    display: none;
  }
}
.select-branch {
  cursor: pointer;
  overflow: hidden;
  max-width: 150px;
  i {
    font-size: 30px;
    color: var(--header-icon) !important;
  }
  .select-title {
    color: var(--header-icon);
    font-size: 10px;
    font-weight: normal;
    @media screen and (max-width: 767px) {
      font-size: 12px;
    }
  }
  .location {
    color: var(--header-icon);
    font-size: 15px;
    font-weight: normal;
    text-transform: capitalize;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    @media screen and (max-width: 767px) {
      font-size: 10px;
    }
  }
}

.css-eo85ql-menu {
  div {
    div {
      &:hover {
        background-color: #e5e5e5;
      }
    }
  }
}
.sections-holder{
  background-color: #FFF0E5;
}

.product-section{
  display: flex;
  flex-direction: row;
  @media (max-width: 800px) {
    flex-direction: column;
  }
}
.tabs-holder{
  background-color: #fff;
}
.login-modal{
  .nav-fill{
    border: 1px solid #e5e5e5;
    border-radius: 15px;
    padding: 1rem;
  }
  div[role="tablist"]{
    padding: 0.25rem;
    .nav-item{
      text-transform: capitalize;
      font-family: 'Poppins';
      color: #000;
    }
    .Mui-selected {
      background: #f5f5f5;
      color: #000;
      border-radius: 15px;
    }
  }
  .css-1aquho2-MuiTabs-indicator{
    background-color: transparent;
  }
  .modal-content{
    background: #fff;
    .modal-header{
      .modal-title{
        color: #000;
      }
      .btn-close{
        transition: all 0.3s ease-in-out;
        &:hover{
          transform: rotate(180deg);
        }
      }
    }
  }
  .modal-body{
    label{
      color: #000;
    }
    .form-text{
      color: #000 !important;
    }
    .login-btn{
      height: 40px;
      background: var(--theme-button-bg) !important;
      border-color: var(--theme-button-bg) !important;
      font-size: 14px;
      border-radius: 10px;
      color: var(--theme-button-text) !important;
      &:hover{
        background: var(--theme-button-text) !important;
        color: var(--theme-button-bg) !important;
        border-color: var(--theme-button-bg) !important;
      }
    }
    .modal-btn{
      height: 40px;
      color: var(--theme-button-bg) !important;
      border-color: var(--theme-button-bg) !important;
      font-size: 14px;
      border-radius: 10px;
      background: var(--theme-button-text) !important;
      &:hover{
        background: var(--theme-button-bg) !important;
        color: var(--theme-button-text) !important;
        border-color: var(--theme-button-bg) !important;
      }
    }
  }
}

.header-icon{
  cursor: pointer;
  height: 25px; 
  display: inline-block;
  svg {
    width: 100%;
    height: 100%;
    path{
      fill: var(--header-icon);
    }
  }
}
.products-section{
  background-color: #FFF0E5;
}
.cards-sections{
  h2{
    color: #000;
    letter-spacing: -0.02rem;
  }
}
.category-banner{
  width: 100%;
  border-radius: 20px;
  margin-bottom: 20px;
}

.cart-page{
  background: #f7f7f7;
  h5, label{
    color: #000;
  }
  h5{
    font-weight: 600;
    font-size: 18px;
  }
}
.form-check-input{
  height: 1.25em;
  width: 1.25em;
  &:checked{
    background-color: #000;
    border-color: #000;
}}
.form-check-label{
  font-size: 14px;
  // margin-top: 15px;
}
.modal-body{
  padding: 2rem;
}
.header-icons{
  font-size: 25px;
  color: var(--header-icon);
  i{
    color: var(--header-icon);
  }
}
.avatar-sm{
  object-fit: cover;
}
.css-13xfq8m-MuiTabPanel-root {
  padding: 15px !important;
}
.favorite-icon{
  &.bxs-heart{
    color: #d13105;
  }
}

.pagination-buttons{
  background: #dadbdd;
  border-radius: 10px;
  button{
    background-color: transparent;
    color: #000 !important;
    border: none;
    line-height: 0;
    font-size: 16px;
    border-color: transparent;
  }
  .btn-primary{
    background-color: #dadbdd;
    border-color: #dadbdd;
  }
}
table{
  font-family: 'Poppins';
}

.thank-modal{
  .icon-top{
    background-color: #d13105;
    animation: pulse 2s infinite;
    @keyframes pulse {
      0%   {box-shadow:0px 0px 30px 20px rgba(209, 49, 5, 0.2);}
      50%  {box-shadow:0px 0px 30px 20px rgba(209, 49, 5, 0.4);}
      100% {box-shadow:0px 0px 30px 20px rgba(209, 49, 5, 0.2);}
    }
  }
}
.subtotal-section{
  span{
    font-size: 12px;
  }
}
.custom-badge {
  margin-left: 10px;
  background-color: red;
  border-radius: 5px;
  padding: 2px;
}
.form-check{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
}
.subVariation_div{
  display: flex;
  align-items: center;
  padding-left: 5px;
}
.counter{
  margin-left: 10px
}
.curr_symbol{
  margin-top: 0;
  margin-bottom: 0;
}
.slick-img{
  max-height: 300px !important;
  width: 100% !important;
}

.slick-image-body .slick-slide{
  max-width: 500px !important;
  max-height: 300px !important;
  border-radius: 10px;
}
.accordion-header{
  border-radius: 25px !important;
}
.accordion-button{
  border-radius: 25px !important;
}

.btn-primary{
  background-color: var(--theme-button-bg);
  color: var(--theme-button-text);
  border: 1px solid var(--theme-button-bg);

  &:is(:hover, :focus, :active) {
    background-color: var(--theme-button-hover-bg);
    color: var(--theme-button-hover-text);
    border-color: var(--theme-button-hover-text);
  }
}
#basic-addon2.btn-primary{
  &:hover{
    background-color: var(--theme-button-hover-bg) !important;
    color: var(--theme-button-hover-text) !important;
    border-color: var(--theme-button-hover-text) !important;
  }
}