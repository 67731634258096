#cart-sidebar {
  width: 380px;
  right: -390px;

  .simplebar-content {
    height: 100%;
  }
}

.right-bar-enabled .cart-sidebar.right-bar {
  right: 0 !important;
}

.cart-sidebar {
  background: #fff;
  padding: 0.75rem;
  h4 {
    margin-bottom: 0;
  }

  .cart-sidebar-head {
    border-bottom: 1px solid #ccc;
    padding: 0.75rem;
  }

  .cart-sidebar-body {
    padding: 0.75rem;
    height: calc(100% - 60px - 48.5px);
  }

  .cart-sidebar-footer {
    border-top: 1px solid #ccc;
    padding: 0.75rem;
    font-size: 16px;
    bottom: 0;
    background: #fff;
    z-index: 99;
  }
}

.payment-methods{
  .nav-item{
    border: 1px solid #ccc;
    border-radius: 23px;
    margin-bottom: 1rem;
    a{
      color: #495057;
    }
    &:hover{
      border: 1px solid #fba96f;
    }
  }
}

@media screen and (max-width: 991px) {
  .cart-sidebar-body {
    height: auto;
    min-height: 100px;
  }
}

@media screen and (max-width: 575px) {
  #cart-sidebar {
    width: 300px;
  }
}
