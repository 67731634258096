
.tab1{
    margin-bottom: 0;
    display: inline-flex;
    padding-left: 0;
    list-style: none;
    a{
        color: var(--nav-text);
        padding: 10px 0 10px;
        font-family: 'Poppins';
        opacity: 1;
        display: block;
        font-size: 16px;
        font-weight: 500;
        width: 100%;
    }
    .tab-group{
        padding: 0 1rem;
        &::after {
            content: '';
            display: block;
            width: 0;
            height: 2px;
            background: var(--nav-active);
            transition: width .3s;
        }
        &:hover{
            &::after {
                width: 100%;
            }
            a{
                color: var(--nav-active) !important;
            }
        }
    }
    .MuiTabs-indicator{
        background: var(--nav-active);
    }
}
.active-button{
    a{
        color: var(--nav-active) !important;
        font-weight: 500 !important;
        opacity: 1 !important;
        border: none;
    }  
    &.tab-group{
        &::after {
            width: 100%;
            padding-inline: 2rem;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
.tab2{
    .tab-group{
        list-style: none;
        &::after {
            content: '';
            display: block;
            width: 0;
            height: 2px;
            background: var(--nav-active);
            transition: width .3s;
        }
        &:hover{
            &::after {
                width: 20%;
            }
            a{
                color: var(--nav-active) !important;
            }
        }
    }
    a{
        color: var(--nav-text);
        padding: 10px 0 10px;
        width: 100%;
        text-align: left;
        display: block;
        font-weight: 500;
        font-size: 16px;
        font-family: 'Poppins';
        opacity: 1;
        @media screen and (max-width: 780px) {
            padding: 20px 25px;
            margin-bottom: 0;
        }
        &:hover{
            color: var(--nav-active) !important;
        }
    }
    // div[role="tablist"]{
    //     flex-direction: column !important;
    //     @media screen and (max-width: 780px){
    //         flex-direction: row !important;
    //     }
    // }
    @media screen and (max-width: 780px){
        display: contents;
        background: #fff;
    }
}
.nav-item{
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 10px;
    border-style: solid;
    border-width: 1px;
    border-color: #ededed;
    justify-content: center;
    color: var(--nav-text);
    background-color: var(--nav-bg);
    border-radius: 35px;
    line-height: 1;
    position: relative;
    width: 100%;
    cursor: pointer;
    display: inline-flex;
    outline: 0;
    transition: all .3s ease;
    align-items: center;
    &:is(:hover,:active){
        background-color: var(--nav-active);
        border-color: var(--nav-active);
        color: var(--active-color);
        .nav-link{
            &:is(:hover,:active){
                color: var(--active-color);
            }
        }
    }
    .nav-link {
        color: var(--nav-text);
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 14px;
        background-color: transparent;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
    }
}
.nav-item{
    .nav-link{
        background: var(--nav-bg);
        color: var(--nav-text);
        &.active{
            background-color: var(--nav-active) !important;
            color: var(--active-color) !important;
            border: none;
        }
    }
}

.sticky-tabs {
    position: fixed;
    top: var(--top-margin);
    right: 0;
    margin-top: 0;
    background-color: #fff;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
    left: 0;
    z-index: 100; 
}
.tab2-holder{
    .css-145v6pe-MuiButtonBase-root-MuiTabScrollButton-root{
        display: none;
        @media screen and (max-width: 780px){
            display: inline-flex;
        }
    }
}

.tab2-stick{
    position: relative;
    margin: 3rem 0;
    div:first-child{
        position: fixed;
        top: var(--top-margin);
        margin-top: 20px;
    }
    @media screen and (max-width: 780px){
        position: fixed;
        top: var(--top-margin);
        right: 0;
        margin: 0;
        background-color: #fff;
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
        left: 0;
        z-index: 100; 
    }
}