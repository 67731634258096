// 
// projects.scss
//

// project list

.project-list-table {
  border-collapse: separate;
  font-family: 'Poppins';
  border-spacing: 0 12px;
  thead{
    background: #f3f3f3;
    color: #b6bcc9;
  }
  tbody{
    tr{
      margin: 2rem 0;
      background: #fff;
      color: #000;
      border-radius: 5px;
    }
  }
}