.bg-Secondary-info {
  background-color: rgb(241, 243, 245) !important;
}

.payment_method_btn {
  background-color: rgb(255, 255, 255) !important;
  color: rgb(0, 0, 0) !important;
  border: none !important;
}

.payment_method_btn:focus {
  border: rgb(235, 34, 37) !important;
}

.cart_header {
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
  background-color: #e2dfdf !important;
}




.cart-items-cont {
  .cart-item {
    padding: 0.75rem;
    margin: 0.25rem;
    background: #fff;
    border-radius: 12px;
    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    img {
      width: 100%;
      border-radius: 10px;
      height: 100px;
      object-fit: cover;
    }

    .cart-item-desc {
      // width: calc(100% - 100px - 1.2rem);
      flex: 1 1 0%;
      .cart-item-name {
        font-size: 16px;
        text-transform: capitalize;
        font-weight: 600;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        color: #000;
      }

      .cart-item-counter {
        display: flex;
        align-items: center;
        border: 1px solid #e2dfdf;
        border-radius: 6px;
        gap: 10px;
        @media screen and (max-width:992px){
          gap: 5px;
        }
        span {
          font-size: 16px;
          font-weight: 500;
          display: inline-block;
          width: 18px;
          text-align: center;
        }
      }

      .counter-style {
        margin-bottom: 0;
        font-size: 20px;
        color: #000;
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        @media screen and (max-width:992px){
          width: 20px;
          height: 20px;
          font-size: 15px;
        }
      }

      .cart-item-price {
        font-size: 14px;
        font-weight: 500;
        color: #000;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .cart-items-cont {
    .cart-item {
      gap: 1rem;

      .cart-item-desc {
        margin-top: 10px;
        // width: calc(100% - 70px - 1rem);

        .cart-item-name {
          font-size: 16px;
        }

        .cart-item-counter {
          span {
            font-size: 14px;
          }
        }

        .counter-style {
          font-size: 16px;
          width: 24px;
          height: 24px;
        }

        .cart-item-price {
          font-size: 16px;
        }
      }
    }
  }
}



.succes {
  background-color: #4BB543;
}
.succes-animation {
  animation: succes-pulse 2s infinite;
}

.danger {
  background-color: #CA0B00;
}
.danger-animation {
  animation: danger-pulse 2s infinite;
}

.custom-modal {
  position: relative;
  width: auto;
  min-height: 250px;
  background-color: #fff;
  border-radius: 30px;
  margin: 40px 10px;
}
.custom-modal .content {
  position: relative;
  width: 100%;
  text-align: center;
}
.custom-modal .content .type {
  font-size: 18px;
  color: #999;
  font-weight: 600;
}
.custom-modal .content .message-type {
  font-size: 24px;
  color: #000;
  font-weight: 600;
}
.custom-modal .border-bottom {
  position: absolute;
  width: 88%;
  height: 20px;
  border-radius: 0 0 30px 30px;
  bottom: -20px;
  margin: 0 25px;
}
.custom-modal .icon-top {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 20px 38%;
  font-size: 30px;
  color: #fff;
  line-height: 100px;
  text-align: center;
}
@keyframes succes-pulse {
  0% {
    box-shadow: 0px 0px 30px 20px rgba(75, 181, 67, .2);
  }
  50% {
    box-shadow: 0px 0px 30px 20px rgba(75, 181, 67, .4);
  }
  100% {
    box-shadow: 0px 0px 30px 20px rgba(75, 181, 67, .2);
  }
}
@keyframes danger-pulse {
  0% {
    box-shadow: 0px 0px 30px 20px rgba(202, 11, 0, .2);
  }
  50% {
    box-shadow: 0px 0px 30px 20px rgba(202, 11, 0, .4);
  }
  100% {
    box-shadow: 0px 0px 30px 20px rgba(202, 11, 0, .2);
  }
}


.page-wrapper {
  height: 100vh;
  background-color: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 80px 0;
}
body { margin:0; font-family: 'Roboto';}
@media only screen and (max-width: 800px) {
  .page-wrapper {
    flex-direction: column;
  }
}

.PhoneInput{
  &.invalid{
    input{
      border: 1px solid red;
    }
  }
  input{
    display: block;
    width: 100%;
    padding: 0.47rem 0.75rem;
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-gray-700);
    appearance: none;
    background-color: var(--bs-custom-white);
    background-clip: padding-box;
    border: 1px solid var(--bs-input-border-color);
    border-radius: 10px;
    height: 45px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-bottom: 0 !important;
  }
}