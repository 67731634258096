.card{
    padding: 15px;
    border-radius: 20px !important;
    background: var(--bg-color);
}
.card-img-top{
    border-radius: 15px;
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
}
.card-body{
    .card-title{
        color: var(--title-color);
    }
    .card-text{
        color: var(--descrip-color);
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .price{
        color: var(--price-color);
    }
}
.img-top1{
    object-fit: cover;
    width: 100%;
    height: 200px;
}
.card-btn1{
    bottom: -35px;
    left: 50%;
    position: absolute;
    text-align: center;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    button{
        border: none;
        font-size: 13px;
        text-transform: uppercase;
        white-space: nowrap;
        align-items: center;
        background-color: var(--btn-bg) !important;
        border-radius: 6px;
        color: var(--btn-color) !important;
        display: flex;
        font-weight: 600;
        margin: 0;
        padding: 8px 10px;
        text-decoration: none;
        transition: all .4s;
    }
   
}
.card-side-img{
    height: 110px;
    object-fit: cover;
}
.truncate-text{
    display: -webkit-box;
    max-width: 200px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.card-btn2{
    position: relative;
    button{
        border: none;
        width: 100%;
        font-size: 13px;
        text-transform: uppercase;
        white-space: nowrap;
        align-items: center;
        background-color: var(--btn-bg) !important;
        border-radius: 6px;
        justify-content: center;
        color: var(--btn-color) !important;
        display: flex;
        font-weight: 600;
        margin: 0;
        padding: 8px 10px;
        text-decoration: none;
        transition: all .4s;
        @media screen and (max-width: 992px) {
            font-size: 10px;
        }
    }
}
.card-1{
    .featured{
        color: var(--btn-color);
        padding: 0 10px;
        font-size: 13px;
        font-weight: 500;
        line-height: 24px;
        display: inline-block;
        position: absolute;
        top: 25px;
        left: 17px !important;
        background: #b38312;
        z-index: 3;
        border-radius: 8px;
    }
}
.card-3{
    .featured{
        color: var(--btn-color);
        padding: 0 10px;
        font-size: 13px;
        font-weight: 500;
        line-height: 24px;
        display: inline-block;
        position: absolute;
        top: 25px;
        left: 90px !important;
        background: #b38312;
        z-index: 3;
        border-radius: 8px;
    }
}
.card-2{
    .featured{
        color: var(--btn-color);
        padding: 0 10px;
        font-size: 13px;
        font-weight: 500;
        line-height: 24px;
        display: inline-block;
        position: absolute;
        top: 15px;
        right: 70px;
        background: #B33012;
        z-index: 3;
        border-radius: 8px;
    }
}

.product{
    padding-left: 15px;
    padding-right: 15px;
    list-style: none;
    margin-bottom: 2.4em;
    position: relative;
    transition: all .4s ease-in-out;
    .onsale{
        color: var(--btn-color);
        padding: 0 10px;
        font-size: 13px;
        font-weight: 500;
        line-height: 24px;
        display: inline-block;
        position: absolute;
        top: 15px;
        right: 60px;
        background: #B33012;
        z-index: 3;
        border-radius: 8px;
    }
    
    .product-block{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        transition: all .4s ease-in-out;
        height: 100%;
        overflow: hidden;
        border-radius: 30px;
        padding: 15px;
        background: var(--bg-color);
        .onsale{
            color: var(--btn-color);
            padding: 0 10px;
            font-size: 13px;
            font-weight: 500;
            line-height: 24px;
            display: inline-block;
            position: absolute;
            top: 25px;
            right: 55px;
            background: #B33012;
            z-index: 3;
            border-radius: 8px;
        }
        
        .product-transition {
            display: block;
            position: relative;
            overflow: hidden;
            margin-bottom: 10px;
            .wishlist{
                position: absolute;
                right: 0;
                top: 0;
                margin-top: 0;
                z-index: 4;
                white-space: nowrap;
                width: 45px;
                height: 45px;
                padding: 0;
                border: 0;
                font-size: 0;
                background: 0 0!important;
                font-weight: 700;
                text-transform: uppercase;
                line-height: 1em;
                color: #1e1d23;
                border-radius: 0;
                &::before{
                    display: inline-block;
                    font-style: normal;
                    font-variant: normal;
                    font-weight: 400;
                    line-height: 1;
                    vertical-align: middle;
                    font-size: 20px;
                    color: #ddd;
                    text-align: center;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    -webkit-transform: translate(-50%,-50%);
                    -ms-transform: translate(-50%,-50%);
                    transform: translate(-50%,-50%);
                }
                &.active{
                    &::before{
                        color: #d13105;
                    }
                }
            }
            .product-img {
                position: relative;
                z-index: 2;
                .thumbnail{
                    display: block;
                    margin: 0 auto;
                    width: 100%;
                    height: 170px;
                    object-fit: cover;
                    -webkit-transform: scale(1);
                    -ms-transform: scale(1);
                    transform: scale(1);
                    border-radius: 20px;
                }
            }
        }
        .product-caption {
            padding: 10px;
            display: inline-block;
            width: 100%;
            .product-title{
                font-size: 16px;
                font-weight: 600;
                line-height: 1.3;
                margin-bottom: 6px;    
                color: var(--title-color);         
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;

            }
            .short-description{
                font-size: 14px;
                line-height: 1.5;
                color: var(--descrip-color);
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                height: 3em;
                margin-bottom: 10px;
            }
        }
        .add-to-cart{
            width: 100%;
            height: 40px;
            border-radius: 8px;
            position: relative;
            font-weight: 500;
            line-height: 0;
            border: none;
            background-color: var(--btn-bg);
            border-color: var(--btn-bg);
            color: var(--btn-color);
            cursor: pointer;
            padding: 0.875em 1.5rem;
            font-size: 14px !important;
            opacity: 0;

            &:is(:active, :focus-visible){
                background-color: var(--btn-bg);
                border-color: var(--btn-bg);
                color: var(--btn-color);
                box-shadow: none;
            }
            .amount, .amount-discountedPrice{
                color: #fff;
            }
            @media screen and (max-width: 767px) {
                opacity: 1;
            }
        }
        .price{
            position: absolute;
        }
        .amount{
            font-weight: 600;
            font-size: 16px;
            color: var(--price-color);
        }
        .amount-discountedPrice{
            font-weight: 600;
            font-size: 16px;
            color: var(--price-color);
            margin-left: 20px;
        }
        &:hover{
            .add-to-cart{
                opacity: 1;
            } 
        }
    }
}
 .add-to-cart{
        font-size: 0!important;
        width: 40px;
        height: 40px;
        border-radius: 16px;
        position: relative;
        font-weight: 700;
        border: none;
        float: right;
        order: 2;
        opacity: 0;
        background: 0 0;
        background-color: var(--btn-bg);
        border-color: var(--btn-bg);
        color: var(--btn-color);
        cursor: pointer;
        padding: 0.875em 2.5em;
        text-decoration: none;
        text-shadow: none;
        display: inline-block;
        @media screen and (max-width: 992px) {
            width: 30px;
            height: 30px;
        }
        i{
            font-size: 20px;
            @media screen and (max-width: 992px) {
                font-size: 16px;
            }
            &::before{
                position: absolute;
                left: 50%;
                top: 50%;
                -webkit-transform: translate(-50%,-50%);
                -ms-transform: translate(-50%,-50%);
                transform: translate(-50%,-50%);
                vertical-align: middle;
            }
        }
        &:hover{
            opacity: 1;
        }
    }
.discount-amount{
    text-decoration: line-through;
    font-weight: 400 !important;
    font-size: 14px !important;
    color: #D21404 !important;
}
.product2{
    .card-body{
        padding-right: calc(var(--bs-gutter-x) * .5);
        padding-left: calc(var(--bs-gutter-x) * .5);
        @media screen and (max-width: 780px) {
            padding-top: 1rem;
        }
    }
    .wishlist{
        position: absolute;
        right: 0;
        top: 3px;
        margin-top: 0;
        z-index: 4;
        white-space: nowrap;
        width: 45px;
        height: 45px;
        padding: 0;
        border: 0;
        font-size: 0;
        background: 0 0!important;
        font-weight: 700;
        text-transform: uppercase;
        line-height: 1em;
        color: #1e1d23;
        border-radius: 0;
        &::before{
            display: inline-block;
            font-style: normal;
            font-variant: normal;
            font-weight: 400;
            line-height: 1;
            vertical-align: middle;
            font-size: 20px;
            color: #ddd;
            text-align: center;
            position: absolute;
            top: 50%;
            left: 0;
            -webkit-transform: translate(-50%,-50%);
            -ms-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
            @media screen and (max-width: 992px) {
                left: 0;
            }
        }
        &.active{
            &::before{
                color: #d13105;
            }
        }
    }
    // .pricing-section{
    //     padding-right: 1rem;
    //     @media screen and (max-width: 992px){
    //         padding-right: 0;
    //     }
    // }
    .add-to-cart{
        font-size: 0!important;
        width: 40px;
        height: 40px;
        border-radius: 12px;
        position: relative;
        font-weight: 700;
        border: none;
        float: right;
        order: 2;
        opacity: 0;
        background: 0 0;
        background-color: var(--btn-bg);
        border-color: var(--btn-bg);
        color: var(--btn-color);
        cursor: pointer;
        padding: 0.875em 2.5em;
        text-decoration: none;
        text-shadow: none;
        display: inline-block;
        @media screen and (max-width: 992px) {
            width: 30px;
            height: 30px;
            opacity: 1;
        }
        i{
            font-size: 20px;
            @media screen and (max-width: 992px) {
                font-size: 16px;
            }
            &::before{
                position: absolute;
                left: 50%;
                top: 50%;
                -webkit-transform: translate(-50%,-50%);
                -ms-transform: translate(-50%,-50%);
                transform: translate(-50%,-50%);
                vertical-align: middle;
            }
        }
    }
    &:hover{
        .add-to-cart{
            opacity: 1;
        }
    }
}

.product-list{
    display: flex;
    align-items: center;
}
.card3-product{
    padding: 0 !important; 
    height: 300px !important;
    .discount-amount{
        color: #ff7e73 !important;
    }
    .wishlist{
        position: absolute;
        right: 5px;
        top: 12px;
        margin-top: 0;
        z-index: 4;
        white-space: nowrap;
        width: 45px;
        height: 45px;
        padding: 0;
        border: 0;
        font-size: 0;
        background: 0 0!important;
        font-weight: 700;
        text-transform: uppercase;
        line-height: 1em;
        color: #1e1d23;
        border-radius: 0;
        &::before{
            display: inline-block;
            font-style: normal;
            font-variant: normal;
            font-weight: 400;
            line-height: 1;
            vertical-align: middle;
            font-size: 20px;
            color: #ddd;
            text-align: center;
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%,-50%);
            -ms-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
        }
        &.active{
            &::before{
                color: #d13105;
            }
        }
    }
    .onsale{
        color: #B33012 !important;
        padding: 0 10px;
        font-size: 13px;
        font-weight: 500;
        line-height: 24px;
        display: inline-block;
        position: absolute;
        top: 15px;
        left: 20px !important;
        right: auto !important;
        background: #fff !important;
        z-index: 3;
        border-radius: 8px;
        box-shadow: 5px 2px 5px -2px rgba(217,215,215,0.75);
        -webkit-box-shadow: 5px 2px 5px -2px rgba(217,215,215,0.75);
        -moz-box-shadow: 5px 2px 5px -2px rgba(217,215,215,0.75);
    }
    .add-to-cart{
        font-size: 0!important;
        height: 25px !important;
        border-radius: 16px;
        position: absolute !important;
        font-weight: 700;
        border: none;
        float: right;
        right: 45px;
        top: 18px;
        width: 25px !important;
        background: 0 0;
        background-color: var(--btn-color) !important;
        border-color: var(--btn-color) !important;
        color: var(--btn-bg) !important;
        cursor: pointer;
        padding: 1rem !important;
        text-decoration: none;
        text-shadow: none;
        display: inline-block;
        i{
            font-size: 22px;
            &::before{
                position: absolute;
                left: 50%;
                top: 50%;
                -webkit-transform: translate(-50%,-50%);
                -ms-transform: translate(-50%,-50%);
                transform: translate(-50%,-50%);
                vertical-align: middle;
            }
        }
    }
    #img-holder{
        position: relative;
        height: 100%;
        &::after{
            content: '';
            position: absolute;
            left: 0; 
            top: 0;
            width: 100%; 
            height: 100%;
            background: linear-gradient(360deg, rgba(0, 0, 0, 0.68) 24.73%, rgba(255, 255, 255, 0.00) 100%);
        }
        .img-top1{
            height: 300px;
            position: relative;
        }
        &:hover{
            &::after{
                background: rgba(253, 105, 71, 0.35);
            }
        }
    }
    
    .card-body{
        position: absolute;
        bottom: 0;
        left: 0;
    }
    &:hover{
      .onsale{
        background: #B33012 !important;
        color: #fff !important;
      }
      .featured{
        background: #B33012 !important;
        color: #fff !important;
      }
    }
  }
.product{
    .product2{
        height: 100%;
        overflow: hidden;
        border-radius: 20px;
        padding: 15px;
        background: var(--bg-color);
        .amount{
            font-weight: 600;
            font-size: 16px;
            color: var(--price-color);
            @media screen and (max-width: 992px) {
                font-size: 13px;
            }
        }
        .amount-discountedPrice{
            font-weight: 600;
            font-size: 16px;
            color: var(--price-color);
            margin-left: 20px;
            @media screen and (max-width: 992px) {
                font-size: 13px;
            }
        }
    }
}
.card2-content{
    padding-right: 5.5rem;
    .card-text{
        height: 3em;
    }
}
.card3-title, .card2-title{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 16px;
}

.card-slider{
    .slick-track{
        margin-left: 0;
        margin-right: 0;
    }
    .slick-list{
        padding: 0 !important;
    }
    .slider-arrow {
        position: absolute;
        z-index: 1;
        height: 100%;
        width: 100%;
      }
    .slick-next, .slick-prev{
        top: -50px;
        &::before{
            content: '';
            width: 35px;
            background-repeat: no-repeat !important;
            height: 35px;
            display: block;
            background-size: 100% !important;
        }
    }
    .slick-next {
        &::before{     
            background: url('../images/icons/next.svg');  
        }
      }
      .slick-prev {
        &::before{
            background: url('../images/icons/prev.svg');
        }
      }
    .slick-prev{
        right: 65px;
        left: auto;
    }
    .slick-next{
        right: 25px;
    }
}
.cart-slider{
    .slick-next, .slick-prev{
        top: -25px;
        &::before{
            content: '';
            width: 25px;
            height: 25px;
        }
    }
    .slick-prev{
        right: 40px;
        left: auto;
    }
    .slick-next{
        right: 10px;
    }
    .product{
        .product2{
            box-shadow: 5px 2px 5px -2px rgba(217,215,215,0.75);
            -webkit-box-shadow: 5px 2px 5px -2px rgba(217,215,215,0.75);
            -moz-box-shadow: 5px 2px 5px -2px rgba(217,215,215,0.75);
        }
    }
}
.add-text{
    font-size: 16px;
    font-weight: 600;
}