// 
// _footer.scss
// 

.footer {
    bottom: 0;
    padding: 40px 0 20px;
    position: relative;
    right: 0;
    left: 0;
    height: auto;
    color: var(--footer-secondary-text);
    background-color: var(--footer-bg-color);

    @media (max-width: 991.98px) {
        left: 0;
    }
    h5{
        color: var(--footer-primary-text);
    }
    .social-icons{
        padding: 0;
        width: 40px;
        height: 40px;
        @media screen and (max-width: 992px) {
            width: 30px;
            height: 30px;
        }
    }
    .app-links{
        width: 120px;
        height: 40px;
    }
}
  
// Enlarge menu
.vertical-collpsed {
    .footer {
        left: $sidebar-collapsed-width;

        @media (max-width: 991.98px) {
            left: 0;
        }
    }
}

body[data-layout="horizontal"] {
    .footer {
        left: 0 !important;
    }  
}
.footer{
    a{
        color: var(--footer-secondary-text);
        font-size: 14px;
    }
    i{
        color: var(--footer-icon);
        border: var(--footer-icon);
    }
    h5{
        margin-bottom: 1.5rem;
        font-weight: 500;
        font-size: 1.12rem;
    }
    ul{
        li{
            margin-bottom: 1rem;
        }
    }
}
.logo-icon{
    margin-bottom: 20px;
    text-align: left;
    img{
        vertical-align: middle;
        display: inline-block;
        height: auto;
        width: 15%;
        filter: brightness(0);
    }
}
.app-section{
    display: none;
}
@media screen and (max-width: 992px){
    .responsive-column{
        width: 50%;
    }
    .follow-section{
        display: none !important;
    }
    .app-section{
        display: block;
    }
}
@media screen and (max-width: 768px){
    .responsive-column{
        width: 100%;
    }
}
