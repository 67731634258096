.image-holder{
    width: 100%;
    img{
        height: 450px;
        object-fit: cover;
        border-radius: 20px;
        width: 100%;
    }
}
.slick-dots{
    bottom: 40px;
    li{
        &.active{
            button{
                &::before{
                    color: #fff !important;
                }
            }
        }
        button{
            width: 30px;
            height: 30px;
            &::before{
                font-size: 16px;
                color: #fff !important;
            }
        }
    }
}

.slider-holder{
    .slick-prev, .slick-next{
        display: none;
    }
}
