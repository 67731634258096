.newsletter-section{
    background: #fff;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin: 0 auto;
    padding: 65px 0;
    color: #000;
    .side-img{
        vertical-align: middle;
        display: inline-block;
        height: auto;
        max-width: 100%;
        img{
            width: 100%;
            height: auto;
        }
    }
    .bg-cover{
        padding: 60px 0;
        @media screen and (max-width:767px) {
            order: 2;
        }
    }
    h3{
        font-family: 'Poppins';
        color: #e25730;
        font-size: 40px;
        font-weight: normal
    }
    h1{
        font-weight: 600;
        font-size: 33px;
        color: #000;
        span{
            color: #e25730;
        }
    }
    p{
        font-size: 12px;
    }
    .input-holder{
        border: 1px solid #dedede;
        border-radius: 13px;
        padding: 7px;
        input{
            background: transparent;
            border: none;
        }
        button{
            border-radius: 13px !important;
            padding: 16px 20px;
            font-weight: 500;
            font-size: 15px;
        }
    }
    @media screen and (max-width:767px) {
        padding: 45px 20px;
    }
}
.right-img{
    @media screen and (max-width: 767px){
        order: 1;
    }
}
.left-img{
    display: flex;
    @media screen and (max-width: 767px){
        display: none !important;
    }
}